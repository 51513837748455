body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre {
  outline: 1px solid #ccc; 
  padding: 8px; 
  margin: 8px;
  overflow: scroll;
  white-space: pre-wrap;
  background-color: #1E1E1E;
  color: #D4D4D4;
}
.string { color: #CE9178; }
.number { color: #B5CEA8; }
.boolean { color: #569CD6; }
.null { color: #569CD6; }
.key { color: #9CDCFE; }

.drawer {
    border-right: 1px solid #DDD;
    overflow: scroll;
    height: 100%;
}

.drawer .title {
    padding: 16px;
}

.drawer .element {
    font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    font-size: 12px;
}
